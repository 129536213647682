<template>
  <v-card>
    <v-card-title>
      <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon>
      <span class="primary--text-kh">{{$t('menu.decisionTypeCreation')}}</span>
      <v-spacer></v-spacer>
      <v-btn class="primary mt-2" medium rounded @click.stop="dialog = true">
        <v-icon left>{{icons.mdiPlusThick}}</v-icon> {{$t('button.newDecisionType')}}
      </v-btn>
    </v-card-title>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <span class="primary--text">{{ dialogTitle }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <br />
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.nameKh')" outlined v-model="caseDecisionType.decisionTypeNameKh" :rules="rule.requiredField()" hide-details></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field :label="$t('text.nameEn')" outlined v-model="caseDecisionType.decisionTypeNameEn" :rules="rule.requiredField()" hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-select v-model="caseDecisionType.phase" outlined label="ដំណាក់កាល" item-value="id" :items="phaseList" hide-details>
                  <template v-slot:selection="data">
                    {{data.item.name}}
                  </template>
                  <template v-slot:item="data">
                    {{data.item.name}}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="save">
            <v-icon dark left>{{icons.mdiContentSave}}</v-icon> {{$t('button.save')}}
          </v-btn>
          <v-btn class="secondary" @click="dialog = false">
            <v-icon left>{{icons.mdiCancel}}</v-icon> {{$t('button.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-card-text>
      <v-data-table :headers="headers" :items="caseDecisionTypeList" :search="search">
        <template v-slot:[`item.phase`]="{ item }">
          <span v-if="item.phase == 1">ដំណាក់កាលអយ្យការ</span>
          <span v-else-if="item.phase == 2">ដំណាក់កាលចៅក្រមស៊ើបសួរ</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="editItem(item)">
            {{icons.mdiPencil}}
          </v-icon>
          <v-icon medium @click="deleteItem(item)">
            {{icons.mdiDelete}}
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiAccount,
  mdiContentSave,
  mdiCancel,
  mdiMagnify,
  mdiPlusThick
} from '@mdi/js'
import SystemService from '@/services/service.system'
import Rule from '@/plugins/rules.js'

export default {
  name: 'case-decision-type',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiMagnify,
        mdiPlusThick,
        mdiAccount
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Decision Type (KH)', value: 'decisionTypeNameKh' },
        { text: 'Decision Type (EN)', value: 'decisionTypeNameEn' },
        { text: 'Phase', value: 'phase' },
        { text: 'ACTION', value: 'actions', sortable: false }
      ],
      phaseList: [
        { id: 1, name: 'ដំណាក់កាលអយ្យការ' },
        { id: 2, name: 'ដំណាក់កាលចៅក្រមស៊ើបសួរ' },
        { id: 3, name: 'ដំណាក់កាលចៅក្រមជម្រះ' }
      ],
      rule: [],
      caseDecisionType: {},
      caseDecisionTypeList: [],
      dialog: false,
      dialogDelete: false,
      search: '',
      resultMessage: '',
      editedIndex: -1
    }
  },
  created() {
    this.rule = Rule
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1
        ? this.$t('caption.newDecisionType')
        : this.$t('caption.editDecisionType')
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  mounted() {
    SystemService.getCaseDecisionTypeAll().then(response => {
      this.caseDecisionTypeList = response.data
    })
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.caseDecisionTypeList.indexOf(item)
      this.caseDecisionType = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.$confirm({
        message: 'Do you want to delete ' + item.evidenceTypeNameKh + '?',
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            SystemService.deleteCrimeTypeById(item.id).then(
              () => {
                const index = this.caseDecisionTypeList.indexOf(item)
                this.caseDecisionTypeList.splice(index, 1)
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: 'Position has been deleted'
                })
              },
              error => {
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'error',
                  text: error.response ? error.response.data : 'An error occurs'
                })
              }
            )
          }
        }
      })
    },
    save() {
      if (!this.$refs.form.validate()) return
      SystemService.insertCaseDecisionType(this.caseDecisionType).then(
        response => {
          if (response.data) {
            if (this.editedIndex == -1) {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.addedCaseLetterType')
              })

              this.caseDecisionTypeList.push(response.data)
            } else {
              this.$notify({
                group: 'message',
                title: 'Notification',
                type: 'success',
                text: this.$t('message.updatedCaseLetterType')
              })
              Object.assign(
                this.caseDecisionTypeList[this.editedIndex],
                response.data
              )
            }
            this.dialog = false
          }
        },
        error => {
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: error.response ? error.response.data : 'An error occurs'
          })
        }
      )
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.caseDecisionType = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    }
  }
}
</script>
